import React from "react";
import styled from "styled-components";
import { mainBlack, mainWhite, screen } from "../../components/variables";

const Wrapper = styled.div`
  animation: initialLoad 1s 0.55s ease forwards;
  opacity: 0;
  width: 40px;
  height: 38px;
  position: absolute;
  left: 0;
  @media ${screen.xsmall} {
    width: 50px;
    height: 40px;
  }

  .burger-icon {
    position: relative;
    width: 40px;
    height: 38px;
    display: block;
    cursor: pointer;
    background: transparent;
    @media ${screen.xsmall} {
      width: 44px;
      height: 36px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -2px;
      width: 40px;
      height: 3px;
      border-radius: 2px;
      background: ${mainBlack};
      transition: background 0.44s ease-out;
      @media ${screen.xsmall} {
        width: 44px;
        height: 3px;
      }
    }
    &:before {
      animation: animationOneReverse 1s ease forwards;
    }
    &:after {
      animation: animationTwoReverse 1s ease forwards;
    }

    .burger {
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 40px;
        height: 3px;
        border-radius: 2px;
        background: ${mainBlack};
        transition: background 0.44s ease-out;
        @media ${screen.xsmall} {
          width: 44px;
          height: 3px;
        }
      }
      &:before {
        top: 7px;
        animation: animationBurgerTopReverse 1s ease forwards;
        @media ${screen.xsmall} {
          top: 6px;
        }
      }
      &:after {
        bottom: 8px;
        animation: animationBurgerBottomReverse 1s ease forwards;
        @media ${screen.xsmall} {
          bottom: 6px;
        }
      }
    }
  }

  .nav-open {
    &:before {
      animation: animationOne 1s ease forwards;
    }
    &:after {
      animation: animationTwo 1s ease forwards;
    }

    .burger {
      &:before {
        animation: animationBurgerTop 1s ease forwards;
      }
      &:after {
        animation: animationBurgerBottom 1s ease forwards;
      }
    }
  }

  // keyframes
  @keyframes animationOneReverse {
    0% {
      transform: rotate(315deg);
    }
    25% {
      transform: rotate(360deg);
    }
    50%,
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes animationOne {
    0%,
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(315deg);
    }
  }

  @keyframes animationTwoReverse {
    0% {
      transform: rotate(405deg);
    }
    25% {
      transform: rotate(450deg);
    }
    50%,
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes animationTwo {
    0%,
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(450deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }

  @keyframes animationBurgerTopReverse {
    0%,
    50% {
      transform: translateY(12px);
      opacity: 0;
    }
    51% {
      transform: translateY(12px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes animationBurgerTop {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      transform: translateY(12px);
      opacity: 1;
    }
    51%,
    100% {
      transform: translateY(12px);
      opacity: 0;
    }
  }

  @keyframes animationBurgerBottomReverse {
    0%,
    50% {
      transform: translateY(-12px);
      opacity: 0;
    }
    51% {
      transform: translateY(-12px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes animationBurgerBottom {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      transform: translateY(-12px);
      opacity: 1;
    }
    51%,
    100% {
      transform: translateY(-12px);
      opacity: 0;
    }
  }

  @keyframes initialLoad {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const IconToggle = ({ logoAndMenuColour, isMenuOpen, setIsMenuOpen }) => {
  return (
    <Wrapper isMenuOpen={isMenuOpen} logoAndMenuColour={logoAndMenuColour}>
      <div
        className={isMenuOpen ? "burger-icon nav-open" : "burger-icon"}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <span className="burger"></span>
      </div>
    </Wrapper>
  );
};

export default IconToggle;
