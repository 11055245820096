import React from "react";
import { mainBlue, mainWhite, mainGreen, screen } from "../variables";
import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Wrapper = styled.footer`
  background: ${mainBlue};
  padding: 40px 0 40px 0;

  @media ${screen.xsmall} {
    padding: 54px 0 21px 0;
  }

  .row {
    display: flex;
    max-width: 600px;

    .col {
      width: 50%;

      .link {
        color: ${mainWhite};
        display: table;
        font-size: 1rem;
        margin: 0 0 12px 0;
        @media ${screen.xsmall} {
          font-size: 1.16rem;
        }
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainWhite};
            color: ${mainBlue};
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &--bot {
      max-width: none;
      margin: 40px 0 0 0;
      @media ${screen.xsmall} {
        margin: 21px 0 0 0;
        justify-content: flex-end;
      }

      p {
        font-size: 0.75rem;
        @media ${screen.xsmall} {
          font-size: 0.9rem;
        }

        a {
          color: ${mainGreen};
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainWhite};
              color: ${mainBlue};
            }
          }
        }

        .break {
          display: block;
        }
      }
    }
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <div className="row">
          <div className="col">
            <AniLink
              cover
              direction="up"
              bg="#010101"
              to="/"
              aria-label="Go to home page"
              className="link"
            >
              Home
            </AniLink>

            <AniLink
              cover
              direction="down"
              bg="#010101"
              to="/services/"
              aria-label="Go to services page"
              className="link"
            >
              Our services
            </AniLink>

            <AniLink
              cover
              direction="up"
              bg="#010101"
              to="/contact/"
              aria-label="Go to contact page"
              className="link"
            >
              Contact
            </AniLink>
          </div>

          <div className="col">
            <a
              className="link"
              rel="noopener"
              aria-label="Visit social platform"
              href="https://www.linkedin.com/company/fmondaysmarketing/"
              target="_blank"
            >
              LinkedIn
            </a>
            <a
              className="link"
              rel="noopener"
              aria-label="Visit social platform"
              href="https://www.instagram.com/fmondaysmktg/"
              target="_blank"
            >
              Instagram
            </a>
            <a
              href="https://www.fmondaysmarketing.com/privacy-policy.pdf"
              className="link"
              rel="noopener"
              aria-label="Open privacy policy"
              target="_blank"
            >
              Privacy policy
            </a>
          </div>
        </div>

        <div className="row row--bot">
          <p>
            &copy; {currentYear} F Mondays Marketing Ltd.{" "}
            <span className="break">
              Design & Build by{" "}
              <a
                href="https://www.hellohandsome.com.au/"
                rel="noopener"
                aria-label="Visit Handsome Creative website"
                target="_blank"
              >
                Handsome Creative.
              </a>
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
