import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  mainFont,
  mainWhite,
  innerWidth,
  screen,
} from "../components/variables";
import "../css/global.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled from "styled-components";
import Navbar from "../components/nav/navbar";
import Footer from "../components/footer";
import useScrollPosition from "@react-hook/window-scroll";

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.div`
  color: ${mainWhite};
  font-family: ${mainFont};

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xsmall} {
      padding: 0 48px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }
`;

const Layout = ({ isServicesPage, isContactPage, children }) => {
  const scrollY = useScrollPosition(60);
  const [logoColour, setLogoColour] = useState(mainWhite);
  const [isStickyNav, setIsStickyNav] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [lastYPos, setLastYPos] = useState(0);

  const isHomePage = !isServicesPage && !isContactPage;

  // useEffect(() => {
  //   if (isHomePage) {
  //     ScrollTrigger.create({
  //       trigger: ".banner",
  //       start: "bottom +=170",
  //       end: "bottom +=85",
  //       onLeave: () => {
  //         // setLogoColour(mainBlack);
  //         setIsStickyNav(true);
  //       },
  //       onEnterBack: () => {
  //         // setLogoColour(mainWhite);
  //         setIsStickyNav(false);
  //       },
  //     });
  //   }
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 200;
      const yPos = window.scrollY;

      // check user if scrolling up
      const isUp = yPos < lastYPos;

      // show nav bar if is on the top of the page or if user scrolling up
      if (isUp) {
        setIsStickyNav(true);
      } else {
        setIsStickyNav(false);
      }

      setIsScrollingUp(isUp);
      setIsScrollTop(isTop);
      setLastYPos(yPos);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, [lastYPos, isStickyNav]);

  const showSticky = scrollY > 170 && isStickyNav;

  return (
    <Wrapper id="layout-wrapper">
      <Navbar
        logoColour={logoColour}
        isStickyNav={showSticky}
        isServicesPage={isServicesPage}
      />
      <main>{children}</main>

      {!isContactPage && <Footer />}
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
