import React, { useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import gsap from "gsap";
import styled from "styled-components";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import {
  mainBlue,
  mainGreen,
  mainWhite,
  innerWidth,
  mainBlack,
  screen,
  withCursor,
} from "../../components/variables";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiFillFacebook,
  AiFillYoutube,
  AiFillTwitterSquare,
} from "react-icons/ai";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Wrapper = styled.div`
  background: ${mainBlue};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  background: ${mainGreen};
  opacity: 0;

  .inner-menu-wrapper {
    width: 100%;
    max-width: ${innerWidth};
    margin: 0 auto;
    position: relative;
    height: 100%;
    padding: 300px 0 50px 0;
    display: flex;
    flex-direction: column;
    padding: 152px 32px 32px 32px;
    @media ${screen.xsmall} {
      padding: 270px 48px 50px 48px;
    }
    @media ${screen.medium} {
      padding: 285px 0 50px 0;
    }
    @media ${screen.large} {
      padding: 300px 0 50px 0;
    }
  }

  .link-list {
    &__each {
      font-size: 1.9rem;
      margin: 0 0 32px 0;
      text-align: center;
      @media ${screen.xsmall} {
        font-size: 3.3rem;
        text-align: left;
        margin: 0 0 35px 0;
      }

      a {
        color: ${mainBlack};
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainWhite};
          }
        }
      }

      .active {
        font-weight: 800;
        pointer-events: none;
      }
    }
  }

  .social {
    align-items: center;
    color: ${mainBlack};
    display: flex;
    flex-direction: column;
    margin: auto 0 0 0;
    opacity: 0;
    @media ${screen.xsmall} {
      align-items: flex-end;
    }

    h6 {
      font-size: 1.2rem;
      @media ${screen.xsmall} {
        font-size: 1.65rem;
      }
    }

    .social-list {
      display: flex;
      margin: 16px 0 0 0;
      @media ${screen.xsmall} {
        margin: 20px 0 0 0;
      }

      &__each {
        a {
          color: ${mainBlack};
          font-size: 2.8rem;
          margin: 0 7px;
          @media ${screen.xsmall} {
            font-size: 3rem;
            margin: 0 0 0 20px;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainBlue};
            }
          }
        }
      }
    }
  }
`;

const OverlayMenu = ({ isMenuOpen, setIsMenuOpen }) => {
  const data = useStaticQuery(dataQuery);

  const { social_list } = data.content.data;

  useEffect(() => {
    const tl = gsap.timeline();

    if (isMenuOpen) {
      tl.to("#menu-overlay-wrapper", {
        opacity: 1,
        display: "flex",
        y: 0,
      }).to(".social", { opacity: 1, y: 0 });
    } else {
      tl.to("#menu-overlay-wrapper", {
        opacity: 0,
        display: "none",
        y: -50,
      }).to(".social", { opacity: 0, y: 30 });
    }
  }, [isMenuOpen]);

  if (typeof window !== "undefined") {
    enablePageScroll();
  }

  if (typeof window !== "undefined" && isMenuOpen) {
    disablePageScroll();
  }

  return (
    <Wrapper id="menu-overlay-wrapper">
      <div className="inner-menu-wrapper">
        <ul className="link-list">
          <li className="link-list__each">
            <AniLink
              cover
              direction="up"
              bg="#010101"
              to="/"
              aria-label="Go to home page"
              activeClassName="active"
            >
              Home
            </AniLink>
          </li>
          <li className="link-list__each">
            <AniLink
              cover
              direction="down"
              bg="#010101"
              to="/services/"
              aria-label="Go to services page"
              activeClassName="active"
            >
              Our services
            </AniLink>
          </li>
          <li className="link-list__each">
            <AniLink
              cover
              direction="up"
              bg="#010101"
              to="/contact/"
              aria-label="Go to contact page"
              activeClassName="active"
            >
              Contact
            </AniLink>
          </li>
        </ul>

        <div className="social">
          <h6>Stay connected</h6>

          <ul className="social-list">
            {social_list.map((item) => (
              <li className="social-list__each" key={item.platform}>
                <a
                  href={item.full_url}
                  aria-label="Visit social platform"
                  target="_blank"
                  rel="noopener"
                >
                  {item.platform === "LinkedIn" && <AiFillLinkedin />}
                  {item.platform === "Facebook" && <AiFillFacebook />}
                  {item.platform === "Twitter" && <AiFillTwitterSquare />}
                  {item.platform === "Instagram" && <AiOutlineInstagram />}
                  {item.platform === "Youtube" && <AiFillYoutube />}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default OverlayMenu;

const dataQuery = graphql`
  {
    content: prismicContactPage {
      data {
        social_list {
          platform
          full_url
        }
      }
    }
  }
`;
