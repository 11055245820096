export const getWebPageSchema = (name, url, description) => {
  return {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name,
    url,
    description:
      description ||
      "A scalable, flexible way to launch your startup or product with outcome-based marketing services.",
    publisher: {
      "@type": "ProfilePage",
      name: "Nikki Francis",
    },
  };
};
