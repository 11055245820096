import React, { useState, useEffect } from "react";
import {
  mainBlue,
  mainBlack,
  mainGreen,
  screen,
  mainBrown,
  mainWhite,
} from "../variables";
import styled from "styled-components";
import Logo from "../../images/svg/fmondays-logo.svg";
import IconToggleLarge from "./icon-toggle-large";
import IconToggleSmall from "./icon-toggle-small";
import gsap from "gsap";
import OverlayMenu from "./overlay-menu";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Wrapper = styled.nav`
  background: ${(props) => (props.isMenuOpen ? mainGreen : props.navBgColour)};
  background: none;
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0;
  transition: all 0.44s ease-out;

  .inner-wrapper {
    position: relative;
    height: 88px;

    @media ${screen.xsmall} {
      height: 180px;
    }
  }

  svg {
    overflow: visible;

    .letters,
    .letter {
      fill: ${(props) =>
        props.isMenuOpen ? mainBlack : props.logoAndMenuColour};
      transition: fill 0.18s ease-out;
    }
  }

  .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 133px;
    height: 38px;
    left: 32px;
    @media ${screen.xsmall} {
      right: 48px;
      width: 220px;
      height: 63px;
      left: auto;
    }
    @media ${screen.medium} {
      right: 0;
    }

    #brand {
      display: block;
      visibility: hidden;
    }
  }

  .icon-toggle {
    position: absolute;
    top: calc(50% - 18px);
    transform: translateY(-50%);
    right: 69px;
    @media ${screen.xsmall} {
      left: 48px;
      right: auto;
      top: calc(50% - 10px);
    }
    @media ${screen.medium} {
      left: 0;
    }
  }
`;

const Stickybar = styled.div`
  background: ${mainWhite};
  background: ${(props) => (props.isMenuOpen ? mainGreen : mainWhite)};
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  transition: all 0.2s ease-out;
  transform: ${(props) =>
    props.isStickyNav ? "translateY(0)" : "translateY(-15px)"};
  pointer-events: ${(props) => (props.isStickyNav ? "all" : "none")};
  opacity: ${(props) => (props.isStickyNav ? 1 : 0)};
  box-shadow: ${(props) =>
    props.isMenuOpen ? 0 : "0 3px 8px -3px rgba(0, 0, 0, 0.175)"};
  @media ${screen.xsmall} {
    transform: ${(props) =>
      props.isStickyNav ? "translateY(0)" : "translateY(-22px)"};
  }

  .inner-wrapper {
    position: relative;
    height: 70px;
    @media ${screen.xsmall} {
      height: 80px;
    }

    svg {
      overflow: visible;

      .letters,
      .letter {
        fill: ${mainBlack};
      }
    }

    .logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 133px;
      height: 38px;
      left: 32px;
      @media ${screen.xsmall} {
        right: 48px;
        width: 148px;
        height: 42px;
        left: auto;
      }
      @media ${screen.medium} {
        right: 0;
      }

      #brand {
        display: block;
        visibility: hidden;
      }
    }

    .icon-toggle {
      position: absolute;
      top: calc(50% - 18px);
      transform: translateY(-50%);
      right: 69px;
      @media ${screen.xsmall} {
        left: 48px;
        right: auto;
        top: calc(50% - 17px);
      }
      @media ${screen.medium} {
        left: 0;
      }
    }
  }
`;

const Navbar = ({ isServicesPage, logoColour, isStickyNav }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navBgColour = isServicesPage ? mainBrown : mainBlue;

  const logoAndMenuColour = isServicesPage ? mainBlack : logoColour;

  useEffect(() => {
    const tl = gsap.timeline();

    tl.from("#brand", { opacity: 0, autoAlpha: 0 }).from(
      ".logo--desktop .letters",
      {
        opacity: 0,
        y: 10,
        stagger: 0.03,
      },
      "<0.3"
    );
  }, []);

  return (
    <>
      <Stickybar isStickyNav={isStickyNav} isMenuOpen={isMenuOpen}>
        <div className="inner-wrapper">
          <div className="logo">
            <AniLink cover direction="down" bg="#010101" to="/">
              <Logo />
            </AniLink>
          </div>

          <div className="icon-toggle">
            <IconToggleSmall
              logoAndMenuColour={logoAndMenuColour}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />
          </div>
        </div>
      </Stickybar>

      <Wrapper
        logoAndMenuColour={logoAndMenuColour}
        navBgColour={navBgColour}
        isMenuOpen={isMenuOpen}
      >
        <div className="inner-wrapper">
          <div className="logo logo--desktop">
            <AniLink cover direction="down" bg="#010101" to="/" id="brand">
              <Logo />
            </AniLink>
          </div>

          <div className="icon-toggle">
            <IconToggleLarge
              logoAndMenuColour={logoAndMenuColour}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />
          </div>
        </div>
      </Wrapper>
      <OverlayMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </>
  );
};

export default Navbar;
